import { AdvantagesType } from 'types/CommonTypes';
import { PrimaryButton } from 'styles/Typography.styled';
import { SingleAdvantage } from 'components/SingleAdvantage/SingleAdvantage';
import React from 'react';
import {
    AdvantageWrapper,
    StyledSectionWrapper,
    Title,
} from './BenefitsPartners.styled';

export interface BenefitsPartnersProps {
    advantages: AdvantagesType[];
    advantagesButton: { title: string; url: string };
    advantagesTitle: string;
}

export const BenefitsPartners = ({
    advantages,
    advantagesButton,
    advantagesTitle,
}: BenefitsPartnersProps) => {
    return (
        <StyledSectionWrapper>
            <Title dangerouslySetInnerHTML={{ __html: advantagesTitle }} />
            <AdvantageWrapper>
                {advantages?.map((item) => (
                    <SingleAdvantage
                        title={item.title}
                        text={item.description}
                        icon={item.icon}
                    />
                ))}
            </AdvantageWrapper>
            <PrimaryButton to={advantagesButton?.url}>
                {advantagesButton?.title}
            </PrimaryButton>
        </StyledSectionWrapper>
    );
};
