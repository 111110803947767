import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    Image,
    ImageMobile,
    LeftWrapper,
    RightWrapper,
    StyledSectionWrapper,
    TextLeft,
    TextRight,
} from './PartnersTextImage.styled';

export interface PartnersTextImageProps {
    descriptionLeft: string;
    descriptionRight: string;
    imageLeft: ImageType;
    imageRight: ImageType;
}

export const PartnersTextImage = ({
    descriptionLeft,
    descriptionRight,
    imageLeft,
    imageRight,
}: PartnersTextImageProps) => {
    return (
        <StyledSectionWrapper>
            <LeftWrapper>
                <Image alt={imageLeft?.altText} src={imageLeft?.sourceUrl} />
                <ImageMobile
                    alt={imageRight?.altText}
                    src={imageRight?.sourceUrl}
                />
                <TextLeft
                    dangerouslySetInnerHTML={{ __html: descriptionLeft }}
                />
            </LeftWrapper>
            <RightWrapper>
                <TextRight>{descriptionRight}</TextRight>
                <ImageMobile
                    alt={imageLeft?.altText}
                    src={imageLeft?.sourceUrl}
                />
                <Image alt={imageRight?.altText} src={imageRight?.sourceUrl} />
            </RightWrapper>
        </StyledSectionWrapper>
    );
};
