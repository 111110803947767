import { BenefitsPartners } from 'sections/BenefitsPartners/BenefitsPartners';
import { HomeGallery } from 'sections/HomeGallery/HomeGallery';
import { PartnersTextImage } from 'sections/PartnersTextImage/PartnersTextImage';
import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import * as React from 'react';
import Layout from 'layout/index';
import { useTheme } from 'layout/ThemeContext';
import { Link } from 'gatsby';
import { getDisabledPages } from 'utils/utils';
import { NotFoundPageInfo } from 'sections/NotFoundPageInfo/NotFoundPageInfo';

interface MaxflizPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: MaxflizPageProps) => {
    const partnersData =
        pageContext?.data?.wpInvestment?.investment?.partnersPage;

    const pageTitle = `${pageContext?.title} - ${partnersData?.title}`;

    if (!pageContext?.data?.wpInvestment?.investment?.enablePartnersPage) {
        return (
            <Layout
                displayPartnersPage={
                    pageContext?.data?.wpInvestment?.investment
                        ?.enablePartnersPage
                }
                headerData={pageContext?.headerData}
                investmentSlug={pageContext?.slug}
                location={location}
                investmentDisabledPages={getDisabledPages(
                    pageContext?.data?.wpInvestment?.investment,
                )}
            >
                <NotFoundPageInfo />
            </Layout>
        );
    }

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={partnersData?.titleBackground?.sourceUrl}
                    text={partnersData?.description}
                    title={partnersData?.title}
                    subPageTitle={partnersData?.subtitle}
                />

                <PartnersTextImage
                    descriptionLeft={partnersData?.descriptionLeft}
                    descriptionRight={partnersData?.descriptionRight}
                    imageLeft={partnersData?.imageLeft}
                    imageRight={partnersData?.imageRight}
                />
                <BenefitsPartners
                    advantages={partnersData?.advantages}
                    advantagesButton={partnersData?.atutyButton}
                    advantagesTitle={partnersData?.advantagesTitle}
                />
                <HomeGallery
                    gallery={partnersData?.gallery}
                    galleryDescription={partnersData?.galleryDescription}
                    galleryTitle={partnersData?.galleryTitle}
                    investmentSlug={pageContext?.slug}
                />
            </>
        </Layout>
    );
};
